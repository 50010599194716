@import '../variables.scss';

.screengeneral {
  max-width: 1200px;
  margin: 0 auto;

  .screenheadline {
    margin-top: 10vh;

    h2 {
      font-size: $h1;
      font-family: $bold;
      color: $primary;
      margin-bottom: 20px;
    }
    p {
      font-size: $text;
      font-family: $regular;
      line-height: 1.5;
      width: 72%;
    }
    a {
      font-family: $bold;
      color: $primary;
      text-decoration: none;
      transition: all 0.3s ease;
    }
    a:hover {
      font-family: $bold;
      color: $blue;
      text-decoration: none;
    }

    .rolehome {
      margin-top: 40px;
      background-color: $accent1;
      padding: 20px;
      width: 50%;
      border-radius: 20px;

      .datenheadline {
        font-family: $bold;
        text-transform: uppercase;
        padding-bottom: 5px;
        color: black;
      }

      p {
        font-size: $small;
        font-family: $light;
        line-height: 1.5;
        width: 72%;
        color: $primary;
      }
    }
  }
}
