/* inter-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'Inter Light';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/inter-v13-latin-300.woff2') format('woff2');
}

/* inter-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Inter Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/inter-v13-latin-regular.woff2') format('woff2');
}

/* inter-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Inter Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/inter-v13-latin-500.woff2') format('woff2');
}

/* inter-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Inter Bold';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/inter-v13-latin-600.woff2') format('woff2');
}
