.chatscreen {
  display: flex;
  justify-content: center;
}

.userlist {
  background-color: white;
  height: 100vh;
  width: 20%;
  max-height: 100vh;
  overflow-y: auto;
}

.chatcard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 3px solid;
  border-color: $accent2;
  transition: 0.3s ease all;

  .profileinfo {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  button {
    display: flex;
    justify-content: center;
    .blockbutton {
      width: 70%;
      justify-content: end;
      align-items: end;
    }
  }
  .profilepic {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background-color: $primary;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: white;
      font-family: $bold;
      font-size: $h3;
      text-transform: uppercase;
    }
  }

  .profiletext {
    margin-left: 20px;
    h3 {
      color: black;
      font-family: $medium;
      font-size: $text;
    }
    p {
      padding-top: 10px;
      font-family: $light;
      font-size: $small;
    }
  }
}

.chatcard:hover {
  background-color: $accent1;
}

.chat {
  height: 100vh;
  width: 80%;

  .no-chat-selected {
    background-color: $accent1;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $regular;
    color: $primary;
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  .chatwindow {
    background-color: white;
    height: 100vh;

    .messages {
      background-color: $accent1;
      max-height: 90vh;
      min-height: 90vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 60px;
      padding-right: 60px;

      .no-chat-message {
        height: 75vh;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: $primary;
          font-family: $light;
        }
      }

      .message-container-left {
        position: relative;
        .timestamp {
          position: absolute;
          font-family: $regular;
          bottom: 5px;
          left: 370px;
          font-size: 12px;
          color: #888;
        }
        .message-left {
          background-color: $green;
          width: 500px;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 10px;
          padding-right: 10px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          font-family: $regular;
          font-size: $text;
          line-height: 1.4;
          margin-bottom: 10px;
        }
      }

      .message-container-right {
        position: relative;
        display: flex;
        justify-content: end;

        .timestamp {
          position: absolute;
          font-family: $regular;
          bottom: 15px;
          right: 15px;
          font-size: 12px;
          color: #888;
        }

        .message-right {
          background-color: $blue;
          width: 500px;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 10px;
          padding-right: 10px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 10px;
          font-family: $regular;
          font-size: $text;
          line-height: 1.4;
          margin-bottom: 10px;
        }
      }
    }

    .emoji {
      position: absolute;
      bottom: 100px;
      right: 0;
    }

    .emoji-button {
      background-color: transparent;
      border: none;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      svg {
        width: 50px;
      }
      path {
        transition: 0.3s ease all;
        fill: $primary;
      }
    }

    .emoji-button:hover {
      path {
        fill: black;
      }
    }
    .inputmessage {
      background-color: #e5e5e5;
      height: 10vh;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        width: 80%;
        height: 50%;
        border-radius: 10px;
        border: none;
        font-family: $regular;
        font-size: $text;
        padding-left: 10px;
      }

      input:focus {
        outline: none !important;
        border: 2px solid $primary;
        box-shadow: none;
      }

      .send {
        height: 60%;
        width: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        svg {
          width: 60%;
        }
        path {
          transition: 0.3s ease all;
        }
      }

      .send:hover {
        path {
          fill: black;
        }
      }
    }
  }
}
