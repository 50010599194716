@import '../variables.scss';

.screentable {
  max-width: 1200px;
  margin: 0 auto;

  .screenheadline {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 10vh;
    margin-bottom: 20px;

    h2 {
      font-size: $h1;
      font-family: $bold;
      color: $primary;
    }
    button {
      font-size: 28px;
      font-family: $bold;
      margin-left: 20px;
      height: 40px;
      width: 40px;
      color: $primary;
      background-color: $accent2;
    }

    button:hover {
      color: $accent1;
      background-color: $primary;
    }
  }
}
