.sidebar {
  background-color: $accent1;
  height: 100vh;
  width: 15vw;

  .mainsidebar {
    background-color: $accent1;
    display: flex;
    padding-left: 2vw;
    height: 90vh;

    ul {
      list-style: none;
      padding-top: 10vh;

      .logo {
        width: 50%;
        margin-bottom: 4vh;
      }

      li {
        margin-bottom: 2vh;
        display: flex;
        align-items: center;
        justify-content: start;

        a {
          display: flex;
          align-items: center;
          justify-content: start;
          color: black;
          text-decoration: none;
          font-size: $text;
          font-family: $regular;
          transition: all 0.3s ease-in-out;
        }

        a:hover {
          color: $primary;
        }

        img {
          width: 30px;
          margin-right: 10px;
        }
      }
    }
  }
  .bottomsidebar {
    height: 10vh;
    display: flex;
    align-items: center;
    padding-left: 2vw;

    button {
      background-color: transparent;
      border: 2px solid $red;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
      color: $red;
      border-radius: 20px;
      font-size: $text;
      transition: all 0.3s ease-in-out;
      font-family: $light;
    }

    button:hover {
      background-color: $red;
      border: 2px solid $red;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
      color: white;
      border-radius: 20px;
      font-size: 1.2rem;
    }
  }
}
