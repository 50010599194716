// BREAKPOINTS
$mobile: 1550;
$desktop: $mobile + 1;

//COLORS
$primary: #f59c46;
$red: #e95031;
$green: #a3ec81;
$blue: #8ad6f5;
$accent1: #f0f0f0;
$accent2: rgba(0, 0, 0, 0.1);

//FONTS
$light: 'Inter Light';
$regular: 'Inter Regular';
$medium: 'Inter Medium';
$bold: 'Inter Bold';

//FONT SIZES
$h1: 2.2rem;
$h2: 1.8rem;
$h3: 1.5rem;
$text: 1.2rem;
$small: 1rem;
