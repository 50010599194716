@import './variables.scss';
@import './base.scss';

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
}

.appmain {
  display: flex;
  justify-content: center;
  max-width: 3000px;
  margin: 0 auto;
}
::-moz-selection {
  background: $primary;
  color: black;
}

::selection {
  background: $primary;
  color: black;
}

.screen {
  background-color: $accent2;
  height: 100vh;
  width: 85vw;
}

.lodaingmessage {
  color: $primary;
  font-family: $light;
  font-size: $h3;
}

.loadingcontainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
